var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single-filter date-filter"},[_c('v-date-picker',{attrs:{"color":"blue","mode":"date","isDark":_vm.$store.state.theme.darkMode,"masks":{
                   input:[_vm.dateFormat],
                   inputDateTime:[_vm.dateFormat+' h:mm A'],
                   inputDateTime24hr:[_vm.dateFormat+' HH:mm'],
                   inputTime:['h:mm A'],
                   inputTime24hr:['HH:mm']
                   },"popover":{visibility: 'click', placement: 'bottom-start'}},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('button',_vm._g({staticClass:"btn btn-filter",class:{'applied': _vm.isApply},attrs:{"id":_vm.filterId,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},inputEvents),[_vm._v("\n                "+_vm._s(inputValue && _vm.isApply ? (_vm.isToday(inputValue) ? _vm.$t('today') : inputValue) : _vm.label)+"\n                "),(_vm.isApply)?_c('span',{staticClass:"clear-icon",on:{"click":function($event){$event.preventDefault();return _vm.clear($event)}}},[_c('app-icon',{attrs:{"name":'x'}})],1):_vm._e()])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }